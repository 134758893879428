// @flow

import React from 'react'
import styled from '@emotion/styled'
import { ThemeProvider } from 'emotion-theming'

import { Button, Hero, Card, Section } from '@toggl/ui'
import { colors, media, themes } from '@toggl/style'

import IndexLayout from '../../layouts'
import LayoutTrack from '../../components/LayoutTrack'
import heroDesktopBg from 'images/uploads/track-desktop-bg.png'
import heroMobileBg from 'images/uploads/track-mobile-bg.png'
import teamDashBoard from '../../../static/images/uploads/teamDashboard.png'
import iconButton from '../../../static/images/uploads/icon-button.png'
import iconSmartphone from '../../../static/images/uploads/icon-smartphone.png'
import iconLaptop from '../../../static/images/uploads/icon-laptop.png'
import { Link } from 'gatsby'
import Signup from 'components/Signup'

const workFontent = [
  {
    img: {
      src: iconLaptop,
      alt: 'Web & Desktop App',
      width: '130px',
      height: '70px',
    },
    title: 'Web & Desktop App',
    details:
      'It’s all here: advanced features for reporting, project management and team auditing. ',
  },
  {
    img: {
      src: iconSmartphone,
      alt: 'Mobile App',
      width: '97px',
      height: '142px',
      marginTop: '10px',
      marginLeft: '10px',
    },
    title: 'Mobile App',
    details:
      '“Oh no, I forgot to put in my hours!” These realizations can happen any time. When you’re on-the-go, quickly log hours and create reports from your mobile or tablet in our apps. ',
  },
  {
    img: {
      src: iconButton,
      alt: 'Toggle Track Button',
      width: '80px',
      height: '80px',
    },
    title: 'Toggle Track Button',
    details:
      'The Toggl Track browser extension works wherever you do. Start the timer directly from Gmail, Asana, Zendesk, or almost anywhere else.',
  },
]

const IndexPage = () => {
  const HeroComponent = ({ children }) => {
    return (
      <Hero.Wrapper bgImage={heroDesktopBg} mobileBgImage={heroMobileBg}>
        {children}

        <Hero.ContentWrapper>
          <HeroLeftContent>
            <Hero.Title>
              Effortless Time-Tracking for <em>Any</em> Workflow
            </Hero.Title>

            <Hero.Text>
              Toggl Track data brings clarity around profitability and helps you
              get more done.
            </Hero.Text>

            <Hero.CtaSection>
              <ThemeProvider theme={themes.track}>
                <Link to={'/track/features'}>
                  <Button.Primary>Get Started for Free</Button.Primary>
                </Link>
                <Link to={'/track/features'}>
                  <Button.Secondary>or discover more</Button.Secondary>
                </Link>
              </ThemeProvider>
            </Hero.CtaSection>
          </HeroLeftContent>
          <HeroRightContent>
            <HeroRightContentImg src={teamDashBoard} />
          </HeroRightContent>
        </Hero.ContentWrapper>
      </Hero.Wrapper>
    )
  }

  return (
    <IndexLayout
      title={'Toggl Track: Effortless Time-Tracking for Any Workflow'}
      description={
        'When time-tracking is this easy, you can get more done. Toggl Track data brings clarity around profitability and helps you make better business decisions.'
      }
      // TODO Aug 28 pic
    >
      <LayoutTrack Hero={HeroComponent}>
        <WorkflowSectionWrapper>
          <Section.Workflow
            title={<span>Toggl Track works where you do</span>}
            subtitle="Online, offline, or on-the-go...we have an app for that!
The best part? Your data instantly syncs across all apps and devices.
"
            link={
              <Link to={'/track/integrations/'}>
                <Button.Primary fluid={false}>
                  See all integrations
                </Button.Primary>
              </Link>
            }
          >
            {workFontent.map((entry, i) => (
              <Card.Workflow {...entry} key={`workflow_story_${i}`} />
            ))}
          </Section.Workflow>
        </WorkflowSectionWrapper>
        <Section.Block background={colors.fadedPurple}>
          <Signup />
        </Section.Block>
      </LayoutTrack>
    </IndexLayout>
  )
}

const WorkflowSectionWrapper = styled.div`
  background-color: ${colors.beige};
  display: flex;
  justify-content: center;
  padding-top: 57px;

  ${media.mq[1]} {
    padding-top: 114px;
    padding-bottom: 128px;
    position: relative;
  }
`

const HeroLeftContent = styled.div`
  ${media.mq[1]} {
    width: 568px;
    float: left;
    margin-bottom: 95px;
  }
`

const HeroRightContentImg = styled.img`
  width: 275px;
  margin-top: 18px;
  ${media.mq[1]} {
    width: 390px;
    margin-top: 106px;
    margin-left: 83px;
  }
`

const HeroRightContent = styled.div`
  text-align: center;

  ${media.mq[1]} {
    display: flex;
  }
`

export default IndexPage
